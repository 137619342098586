<template>
    <div id="usage">

                <img class="illustration" :src="getImgUrl" :alt="data.itemsTxt[item]" />
                <span class="legende" v-if="!data.items[item]">
                    <span v-html="data.itemsTxt[item]"></span>
                </span>
  <transition name="slide-fade">
                <span class="legende titre" v-if="data.items[item]">
                    <span v-html="data.itemsTxt[item]"></span>
                </span>
  </transition>
  <transition name="slide-fade">
                <div class="contenu" v-if="data.items[item]">
                    <span v-html="data.itemsCont[item]"></span>
                </div>
  </transition>

                <img v-if="item!='reseau' || $parent.$parent.$parent.addeoSmall" class="fond" src="../../assets/img/usages/cadreNormal.png" alt="" />
                <img  v-if="item!='reseau' || $parent.$parent.$parent.addeoSmall" class="fond survol" src="../../assets/img/usages/cadreSurvol.png" alt="" />
                <img v-if="item=='reseau' && !$parent.$parent.$parent.addeoSmall" class="fond" src="../../assets/img/usages/cadreLarge.png" alt="" />
                <img  v-if="item=='reseau' && !$parent.$parent.$parent.addeoSmall" class="fond survol" src="../../assets/img/usages/cadreSurvolLarge.png" alt="" />

    </div>
</template>

<script>
export default {
    name: 'Usage',
        props: {
            data: {
                type: Object
            },
            item: {
                type: String
            }
        },
    data() {
        return {
            tmp:true
        }
    },
    computed: {
        getImgUrl() {
            if (this.$parent.$parent.$parent.addeoSmall) {
                var image = "/"+this.data.imagesS[this.item];
            }
            else {
                var image = "/"+this.data.images[this.item];
            }
            //var toto = require(image);
            //return imageName ? toto : ''
            return  image
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #usage {

/*



.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
 {
  transform: translateY(50%);
  opacity: 0;
}
   */
    }
</style>
