<template>
  <div id="connexionForm" class="dansBloc">

    <div id="container-form" class="">

        <h3>Pas encore de compte PRO ?</h3>

        <a :href="inscriptionProPasPro" tabindex="3" class="bouton lien" target="_top">{{labelPasDeCompte}}</a>

        <div class="edition">
<!--
Attention aux ID, le même form en header et footer !!!
            <form id="connexionEditionForm" ref="connexionEditionForm" class="login_form" method="post" :action="lURLConnexion" accept-charset="utf-8" @submit="validerMonFormulaire">
-->

            <h3>Déjà inscrit ?</h3>

            <form class="login_form"  method="post" :action="lURLConnexion" accept-charset="utf-8" @submit="validerMonFormulaire">

                    <div class="blocConnexion">
                        <v-text-field
                            label="Adresse courriel"
                            class="adresse_mail"
                            name="data[Utilisateur][adresse_mail]"
                            v-model="form.adresse_mail"
                            :error-messages="error.adresse_mail"
                            ref="adresse_mail"
                        ></v-text-field>
<!--
// 2022/01 plus de validation de forme ... pour le moment
                            required
-->

                    </div>

                    <div class="blocConnexion blocPasswordConnexion">

                        <v-text-field
                            v-if="showPassword"
                            label="Mot de passe"
                            type="text"
                            class="mot_de_passe"
                            name="data[Utilisateur][mot_de_passe]"
                            v-model="form.mot_de_passe"
                            :error-messages="error.mot_de_passe"
                            ref="mot_de_passe"
                        ></v-text-field>
                        <v-text-field
                            v-if="!showPassword"
                            label="Mot de passe"
                            type="password"
                            class="mot_de_passe"
                            name="data[Utilisateur][mot_de_passe]"
                            v-model="form.mot_de_passe"
                            :error-messages="error.mot_de_passe"
                            ref="mot_de_passe"
                        ></v-text-field>
<!--
// 2022/01 plus de validation de forme ... pour le moment
                            required
-->

                <img class="iconeMotDePasse" v-if="!showPassword" src="../assets/img/oeil.svg"  @click="toggleShow" />
                <img class="iconeMotDePasse" v-if="showPassword" src="../assets/img/oeilb.svg"  @click="toggleShow" />

<!--
// 2022/01 plus de font awesome ... pour le moment
                        <i class="iconeMotDePasse fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="toggleShow"></i>
-->

                        <a :href="oublieProPasPro" tabindex="4" class="mdp_oublie" target="_top">Mot de passe oublié ?</a>
                    </div>

                    <div class="boutonsValidation">
                        <v-btn class="a-btn prioritaire registerBD" type="submit" @click="prevaliderMonFormulaire">Me connecter</v-btn>
                    </div>
                </form>
                <span class="ou gauche">ou</span>
                <span class="ligne gauche">&nbsp;</span>
                <div class="clear" />

                <div id="bn">
                    <span class="gauche">Se connecter avec</span>
                    <a href="https://connexev3.recette.grandest.fr/gecas/login?service=https://preprod.lorfolio.fr&ent=true" target="_blank">
                        <img  src="../assets/img/pro/pourqui/bn.png"  />
                    </a>
                </div>
        </div>

    </div>

  </div>
</template>


<script>

import { addeoPost } from "../js/datas.js"

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, minValue, maxValue } from 'vuelidate/lib/validators'



export default {
    name: 'connexionForm',
    data() {
        return {
            showPassword: false,
            form:  {
                adresse_mail:" ",
                mot_de_passe:" "
                /* si correction 2022/02 pour Chrome
                adresse_mail:"",
                mot_de_passe:""
                */
            },
            data:  {
                Utilisateur:  {
                    adresse_mail:"",
                    mot_de_passe:""
                }
            },
            old: {

            },
            error: {
                mot_de_passe: '',
                adresse_mail: '',
            },
            ready:false,
        }
    },
    components: {
    },
    props: {
    },
    created() {
/*
        if (this.sitePro) {
            this.$parent.sitePRO = true;
        }

        if (this.sitePRO && this.lesParams == 'AgendaPro') {
        }
*/
        this.data["Utilisateur"] = this.form;

    },

    mounted() {
        setTimeout(this.reinit, 100);
    },

    validations() {
        return {
            form: {
                mot_de_passe: { required, maxLength: maxLength(65) },
                adresse_mail: { required, email },
            }
        }
    },
    computed: {
        inscriptionProPasPro() {
            var urlInscription = this.lURL;
            urlInscription += "/utilisateurs/inscription";
            if (this.$parent.sitePRO) {
                urlInscription += "_pro"
            }
            return(urlInscription);
        },
        labelPasDeCompte() {
            return("Je crée mon compte");
        },
        oublieProPasPro() {
            var urlOublie = this.lURL;
            urlOublie += "/utilisateurs/raz_mdp_initial";
            if (this.$parent.sitePRO) {
                urlOublie += "/pro"
            }
            return(urlOublie);
        },
/*
        sitePRO() {
            return(this.$parent.sitePRO);
        },
        lesParams() {
            return(this.$route.params.pere);
        },
*/
        lesPros() {
            //return("/utilisateurs/identification")
            return("/utilisateurs/identification/0/pro");
        },
        lesAutres() {
            return("/utilisateurs/identification");
        },
        lURL() {
            var leDomaine = window.location.hostname;

            // quel domaine
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                return("https://grandtest.addeobx.bsq"); // URL de test
            }
            else {
                if (leDomaine.indexOf("grandtest") >= 0) {
                    return("https://grandtest.addeobx.bsq"); // URL de test
                }
                else {
                    if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                        return("https://preprod.lorfolio.fr"); // URL de preprod
                    }
                    else {
                         if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                            return('https://mon.lorfolio.fr'); // URL de prod
                         }
                    }
                }
            }
        },
        lURLConnexion() {
            var urlIdentification = this.lURL;
            // pro ou pas pro ?
            if (this.$parent.sitePRO) {
                urlIdentification += this.lesPros;
            }
            else {
                urlIdentification += this.lesAutres;
            }
            return(urlIdentification);
            //return("https://grandtest.addeobx.bsq/utilisateurs/identification"); // en https !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        },

    },
    methods: {
        reinit() {
            this.form.adresse_mail = "";
            this.form.mot_de_passe = "";
            this.data["Utilisateur"] = this.form;
        },

        toggleShow() {
          this.showPassword = !this.showPassword;
        },

        prevaliderMonFormulaire(e) {
/*
// 2022/01 plus de validation de forme ... pour le moment
            var thisObj = this;
            // on reinit à vide les messages d'erreur
            thisObj.reinitErrorMessages();

            // si pb de validation, on retourne false et on définit les messages d'erreur
            if (thisObj.$v.$invalid) {
                // maj erreurs
                thisObj.motPasseErrors();
                thisObj.adresseMailErrors();
                 e.preventDefault();
                return false;
            }
*/
            return true;

        },
        validerMonFormulaire(e) {
// 2022/01 plus de validation de forme ... pour le moment
/*
            // si pb de validation, on sort
            if (this.$v.$invalid) {
                 e.preventDefault();
                return false;
            }
*/
            return true;
        },

        // validation du formulaire
        reinitErrorMessages() {
            this.error.adresse_mail = ''
            this.error.mot_de_passe = ''
        },


        adresseMailErrors () {
            const errors = [];
            if (!this.$v.form.adresse_mail.email || this.$v.form.adresse_mail.$invalid) {
                this.error.adresse_mail = "Vous devez saisir une adresse mail valide";
                this.$refs.adresse_mail.focus();
                return false;
            }
            else {
                this.error.adresse_mail = "";
            }
            return true;
        },
        motPasseErrors () {
            const errors = [];
            if (this.$v.form.mot_de_passe.$invalid) {
                this.error.mot_de_passe = "Vous devez saisir votre mot de passe";
                this.$refs.mot_de_passe.focus();
                return false;
            }
            else {
                this.error.mot_de_passe = "";
            }
            return true;
        },

    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .addeoSmall {
        /*
        #contact {
            #container-form {
                div.edition {
                    width: 95%;
                    max-width: 1000px;
                }
                .boutonsValidation {
                    button {
                        width:75%;
                    }
                }
            }
        }
        */
        #connexionForm {
            #container-form {
                text-align: center;

                div.blocConnexion {
                    text-align: left;
                    width: 90%;
                    margin:0 auto 1em;
                }

                .boutonsValidation {
                    float:none;
                    width:100%;
                    text-align: center;
                    button {
                        margin:0 auto;
                    }
                }
            }
        }
    }

    .addeoMedium {
        #connexion {
            .containerConnexion {
/*
                max-width: 700px;
                width:75%;
                margin:0 20px 0 150px;
                float:right;
*/

                @media screen and (max-width: 700px) {
                    width:90%;
                    margin:0 5%;
                }

                #connexionForm {
                    #container-form {
                        .blocConnexion {
                            font-size:90%;

                            .v-input {
                                .v-input__slot, .v-text-field__slot {
                                    label {
                                        font-size:85%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #connexionForm {
        font-family: Roboto, Arial, sans-serif;
        $couleur-fond: #eee;
        $couleur-texte: #223938;
        display:inline-block;
        width: 100%;
        margin-top:20px;

        div {
            display:inline-block;
        }

        #container-form {
            text-align: center;
            width: 100%;
            color:rgb(51, 51, 51);

            div.consigne {
                margin:1em 0;
                font-size: 0.9em;
            }
            .consigne {
                font-style: italic;
                display: inline-block;
            }

            div.edition {
                width: 100%;
                max-width: 600px;
                /*margin: 1.0em auto 0.8em;*/
                margin: 1em auto;
                text-align: left;
                form {
                    margin: 0.8em auto 2em;
                    padding:0.4em 0.4em 1em;
                    background-color: #E7EAEC;
                    text-align: center;
                }
                span.ou {
                    width:10%;
                }
                span.ligne {
                    width:90%;
                    max-height: 1px;
                    min-height: 1px;
                    background-color: grey;
                }
                .gauche {
                    float:left;
                }
                span, a {
                    vertical-align: middle;
                }
            }

            .blocConnexion {
                width: 96%;
                max-width: 342px;
                margin: 1em auto 0;
                vertical-align: top;

                .iconeMotDePasse {
                    position: absolute;
                    top: 12px;
                    right: 10px;
                    cursor: pointer;
                    /*font-size: 120%;*/
                    max-width: 20px;
                    opacity: .7;
                    /*fill: #356a73;*/
                    &:hover {
                        opacity: 1;
                    }
                }
                &.blocPasswordConnexion {
                    .v-text-field {
                        .v-text-field__slot {
                            input {
                                width: 78%;
                            }
                        }
                    }
                }
                .v-input {
                    .v-input__slot, .v-text-field__slot {
                        label, input {
                            font-size:90%;
                        }
                    }
                }
            }

            .v-input {
                display:inline-block;
                margin: 0;
                padding:0;
                /*
                width: 33%;
                max-width: 280px;
                */
                width: 100%;

                .v-input__slot, .v-text-field__slot {
/*
                    background-color: #F8F8FC;
                    color: #2C3E50;
*/
/*
                    background-color:#EBEBEB;
                    color:#252525;
*/
                    border-radius: 4px 4px 0 0;
                    width:100%;
                    margin-bottom:0;

                    input, textarea {
                        /*font-size:85%;*/
                        font-size:100%;
                        margin:0 5%;
                        /*padding:1.2em 0 0.4em;*/
                        padding:1.0em 0 0.1em;
                        width:90%;
                        &:focus, :focus {
                            outline:transparent solid 0px !important;
                        }
                    }
                    label {
                        left:1em !important;
                        top:0.6em !important;
                        right:auto !important;
                        color:#356A73;
                        /*color:#252525;*/
                        width: 100%;
                    }
                    .v-label--active {
                        transform: translateY(-8px) scale(.6);
                        left: -15% !important;
                        right:unset !important;
                    }
                }
                .v-text-field__details {
                    height: 9px;
                    /*
                    position: absolute !important;
                    top: -15px;
                    */
                    left: 0;
                    .v-messages {
                        min-height: 0px;
                        display:inline;
                        .v-messages__message {
                            color:darkorange;
                            font-size: 85%;
                            vertical-align: top;
                        }
                    }
                }
            }
            .v-input:not(.v-input--is-label-active) {
                .v-input__slot {
                    .v-text-field__slot {
                        input {
                            color:transparent;
                        }
                    }
                }
            }
            .bouton {
                font-family: Montserrat, Roboto, Arial, sans-serif;
                background-color: white;
                border-radius: 4px;
                box-shadow: none;
                border: 2px solid #356A73;
                max-width: 200px;
                text-align: center;
                margin :1em auto 1em;
                font-size:0.9em;
                padding: 0.6em 0.4em;
                &:hover {
                    background-color: #356a73;
                    color: #fff;
                }
            }
            .boutonsValidation {
                font-family: Montserrat, Roboto, Arial, sans-serif;
                /*float: right;*/
                button {
                    font-size: 70%;
                    letter-spacing: 0.03em;
                    /*
                    padding: 0 10px;
                    margin-top:0.4em;
                    */
                    padding: 2px 9px;
                    margin-top:1em;
                    height:30px;
                    text-transform: none;

                    color:white;
                    background-color: #356A73;
                    border-radius: 0;
                    box-shadow: none;
                    border: 1px solid #356A73;
                    .v-btn__content {
                        background-color: transparent;
                    }
                    &:hover {
                        background-color: #fff;
                        color: #356a73;
                        &:before  {
                            opacity: 1;
                            background-color: transparent;
                            transition: none;
                        }
                    }
                }
            }

        }

        &.dansBloc {
            #container-form {
                a {
                    display:block;
                    color:#356A73;
                    &:hover {
                        text-decoration: none;
                        opacity: 0.8;
                    }
                }

                .blocConnexion {
                    a {
                        margin-top:0.2em;
                        font-size: 70%;
                        text-align: right;
                        text-decoration: underline;
                    }
                    display:block;
                    .v-input {
                        .v-text-field__slot {
                            /*background-color:#EBEBEB;*/
                            background-color:#FFF;
                            color: #2C3E50;
                        }
                        label {
                            /*color:#356A73;*/
                        }
                    }
                }

                .boutonsValidation {
                    button {
                        /*
                        color:white;
                        background-color: #356A73;
                        */
                    }
                }
            }
        }
        #bn {
            width:100%;
            margin-top:1em;
            span {
                margin:1em 2em 0 0;
            }
            a {
                img {
                    max-width: 200px;
                }
                display: inline-block;
            }
        }

    }



html {
    body {
        #app.addeoSmall, #app.addeoMedium, #app.addeoLarge  {
            #message.messageErreur {
                background-color: firebrick !important;
            }
        }
    }
}



/**/
    @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v67/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
    /*
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
    */
    /**/
</style>
