<template>
  <div id="footer" v-bind:class="{markOrientest : markOrientest}">
      <div id="containerFooter">
        <footer-infos/>
        <footer-menu  />
        <footer-social  />
        <div  class="clear"></div>
      </div>
  </div>
</template>

<script>

import FooterInfos from '@/components/footer/FooterInfos.vue'
import FooterMenu from '@/components/footer/FooterMenu.vue'
import FooterSocial from '@/components/footer/FooterSocial.vue'
import Connexion from '@/components/Connexion.vue'

export default {
    name: 'Footer',
    props: {
    },
    components: {
        'footer-infos': FooterInfos,
        'footer-menu': FooterMenu,
        'footer-social': FooterSocial,
        'bloc-connexion': Connexion,
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        sitePro() {
            return(this.$parent.$parent.sitePRO);
        },
        isAccueil() {
            if(this.$parent.accueil) {
                return(true);
            }
            return(false);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
    #footer {
        /*margin-top:4em;*/
        width:100%;
        background-color: #4B3C8F;
        border-top: 5px solid #33AD98;
        padding: 3em 0 2em;

        &.markOrientest {
            background-color: #EDF5F9;
            border-top: 5px solid #006EAB;
            padding: 3em 0 2em;
            font-family: Oxygen, Roboto, Arial, sans-serif;

            a {
                /*color: #006EAB;*/
                color: #006EA9; /* cohérence orientEst.fr */
            }
            .itemFooter {
                /*font-size: 1.4em;*/
            }
            #barre {
                background: no-repeat center center transparent url("~@/assets/img/oe/barre.svg");
                }

            ul.marquerLiensExternes li a[href*="http"]::after {
              content: url("~@/assets/img/oe/out.svg");
              height: auto;
              display: inline-block;
              width: 12px;
              margin-left: 2px;
              vertical-align: super;
              vertical-align: top;
              margin-top: -5px;
            }
        }

        #containerFooter {
            max-width: 800px;
            margin:0 auto;
            width: 100%;

            #footerSocial { /* à l'occasion d'OrientEst mais pour tout */
                margin-top: 1em;
                float:left;
            }
        }


        #barre {
            background: no-repeat center center transparent url("~@/assets/img/footer/barre.svg");
            min-height: 5em;
            width:90%;
            max-width: 800px;
            margin:0 auto;
            opacity: 0.5;
        }
        &:not(.markOrientest) {
            .itemFooter {
                color:white;
                a {
                    &:hover {
                        color:white;
                    }
                }
            }
        }
        .itemFooter {
            display:inline-block;
            width:50%;
            max-width: 400px;
            vertical-align: top;

            a {
                &:hover {
                    opacity:0.5;
                }
            }
        }
    }

    .addeoMedium {
        #footer {
            padding-left: 5%;
            padding-right: 5%;
            width:90%;

            .itemFooter {
                width:42.5%;
                max-width: 100%;


                &#footerSocial {
                    margin:0 5% 2em 5%; /* à l'occasion d'OrientEst mais pour tout */
                }

            }

            &:not(.markOrientest) {
                .itemFooter {
                    &:first-of-type {
                        margin-right: 5%;
                    }
                }
            }

            @media screen and (max-width: 800px) {
                padding-left: 2.5%;
                padding-right: 2.5%;
                width:95%;

                &:not(.markOrientest) {
                    .itemFooter {
                        &:first-of-type {
                            margin-right: 10%;
                        }
                    }
                }
            }
        }
    }

    .addeoSmall {
        #footer {
            width:100%;
            #containerFooter {
                .itemFooter {
                    padding-left: 2.5%;
                    padding-right: 2.5%;
                    width:95%;
                    &#footerSocial {
                        float:none; /* à l'occasion d'OrientEst mais pour tout */
                    }
                }
            }

            &:not(.markOrientest) {
                #containerFooter {
                    .itemFooter {
                        margin-bottom:2em;
                    }
                }
            }
        }
    }
</style>
