<template>
    <div id="conteneurHeaderLogosPro">
        <div id="headerLogosPro">
            <img v-if="0 && !addeoSmall" src="../../../assets/img/pro/header/evolution-emploi.png" alt="évolution, orientation, insertion, formation, emploi" class="nombril dessous" />
            <div id="logoLorfolio" class="itemLogo dessus">
                <a href="/" class="" title="Lorfolio" v-if="markOrientest">
                    <img src="../../../assets/img/pro/header/logoBaoHaut.svg" alt="BàO OrientEst" class="centrer" />
                </a>
                <a href="/" class="" title="Lorfolio" v-if="!markOrientest">
                    <img src="../../../assets/img/pro/header/logoLorfolioPro.svg" alt="Lorfolio" class="centrer" />
                </a>
            </div>
            <div v-if="addeoLarge" id="logoGE" class="itemLogo dessus">
                <a href="https://www.grandest.fr" target="_blank" rel="noopener" class="" title="Région Grand Est">
                    <img src="../../../assets/img/header/logoGrandE.svg" alt="Région Grand Est" class="centrer" />
                </a>
            </div>
            <h1 id="facilite"  class="dessus">
                <span>Tout pour informer sur les métiers et les formations</span>
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderLogos',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed:{
        addeoSmall() {
            return(this.$parent.$parent.$parent.addeoSmall);
        },
        addeoMedium() {
            return(this.$parent.$parent.$parent.addeoMedium);
        },
        addeoLarge() {
            return(this.$parent.$parent.$parent.addeoLarge);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    #app.pro {
        #conteneurHeaderLogosPro {
            background: no-repeat center bottom #F3C935 none;
            /*background: no-repeat center bottom transparent url("~@/assets/img/pro/header/bas.png");*/
            background: no-repeat center bottom #F3C935 url("~@/assets/img/decoupageHeader.png");
        }
        #header {
            background-color: #F3C935;
            background-image: none;

            .gauche {
                background: no-repeat left bottom transparent url("~@/assets/img/pro/header/perso-gauche.png");
                width: 50%;

                @media screen and (max-width: 1750px) {
                    background: no-repeat left bottom transparent url("~@/assets/img/pro/header/perso-gauche-petits.png");
                }

                @media screen and (max-width: 1250px) {
                    background-position: right bottom;
                    width: 35%;
                }
            }
            .droite {
                background: no-repeat right bottom transparent url("~@/assets/img/pro/header/perso-droite.png");
                width: 50%;

                @media screen and (max-width: 1750px) {
                    background: no-repeat right bottom transparent url("~@/assets/img/pro/header/perso-droite-petits.png");
                    width: 50%;
                }
/*
                @media screen and (max-width: 1550px) {
                    background-position: left bottom;
                    width: 32%;
                }
*/
                @media screen and (max-width: 1250px) {
                    background-position: left bottom;
                    width: 30%;
                }
            }

            .nombril {
                @media screen and (max-width: 1550px) {
                    max-width:720px;
                    top:5%;
                }
            }
        }
        &.addeoMedium {
            &:not(.addeoLargeLow) {
                #header {
                    .gauche {
                            background-position: right bottom;
                            background-size: contain;
                    }
                    .droite {
                            background-position: left bottom;
                            background-size: contain;
                    }
                }
            }
        }

    }
.addeoMedium {
        #header {
            #headerLogosPro {
                text-align: center; /*2021/07 */
                min-height: 300px;
                h1 {
                    margin:0 auto; /*2021/07 */
                    /*margin:0; 2021/07 */
                }

                .itemLogo {
                    &#logoLorfolio {
                        text-align: center; /*2021/07 */
                        /*width:34%;*/
                        /*padding: 0 0 0 8%;*/
                    }
                }
                #facilite {
                    text-align: center; /*2021/07 */
                    width:90%;
                    padding-left: 5%; /*2021/07 */
                    padding-right: 5%; /*2021/07 */
                    /*font-size:3.4vw ;*/
                        font-size:1.6em ;
                    /*width:auto;
                    padding: 0; 2021/07 */
                    line-height: 1em;
                    vertical-align: top;

                    .retourLigne {
                        /*
                            display: block !important;
                            position: relative;
                            padding: 0;
                            margin: 0;
                            width: 0;
                            height: 0;
                        */
                        }
/*
                    @media screen and (min-width: 800px) {
                        font-size:1.6em ;
                    }
*/
                }

                .nombril {
                    width: 65%;
                    min-height: unset !important;
                    top: 50px;
                }
/*
                @media screen and (max-width: 860px) {
                    min-height: 360px;
                    .nombril {
                        width: 55%;
                        top: 50px;
                        min-width: 380px;
                        }
                }
*/

            }

        }

    &:not(.addeoLargeLow) {
        #header {
            #headerLogosPro {
                #facilite {
                    max-width: 450px;
                }
            }
        }
    }
    &.addeoLargeLow {
        #header {
            #headerLogosPro {
                .nombril {
                    width: 50%;
                    top: 40px;
                }
            }
        }
    }
}
    #app {
        &.addeoSmall {
            &.pro {
                #header {
                    .gauche {
                        background: no-repeat left bottom transparent url("~@/assets/img/pro/header/perso-gaucheS.png");
                        width: 100%;
                        display:block;
                    }
                    .droite {
                        display:none;
                    }
                }
            }
        }
    }

    .addeoSmall {
        #headerLogosPro {
            text-align: center;
            /*min-height: 400px;*/
            min-height: 240px;
/*
            background: no-repeat center bottom transparent url("~@/assets/img/pro/header/evolution-emploi-small.png");
            background-size:contain;
*/
            @media screen and (max-width: 442px) {
/*
            background: no-repeat center bottom transparent url("~@/assets/img/pro/header/evolution-emploi-xsmall.png");
                background-size:contain;
*/
            }
            @media screen and (max-width: 380px) {
/*
                background: no-repeat center bottom transparent url("~@/assets/img/pro/header/evolution-emploi-xxsmall.png");
                background-size:contain;
*/
                }

            .itemLogo {
                text-align: center;

                &#logoLorfolio {
                    width:90%;
                    margin:0 auto;
                    padding: 0;

                    a {
                        width:100%;
                        max-width: 300px;
                        display:inline-block;
                        text-align: center;
                    }
                }
            }
                #facilite {
                    text-align: center;
                    width:94%;
                    margin:0.4em 3% 0;
                    padding: 0;
                    font-size: 1.4em;
                    line-height: 1.1em;
                    text-align: center;
/*
                    @media screen and (max-width: 500px) {
                        font-size: 6vw;
                    }
*/
                    span {
                        width:100%;
                        max-width: 400px;
                        display:inline-block;
                        /*text-align: left;*/
                    }
                }

            .nombril {
                display:none;
                /*
                width: 80%;
                top:65%;
                transform: translateY(-50%) translateX(-50%);
                min-height: unset !important;
                min-width: 320px;
                max-width: 100%;

                @media screen and (max-width: 360px) {
                    top:60%;
                }
                */
            }
        }
    }
    #headerLogosPro {
        width:96%;
        max-width: 1200px;
        /*min-height: 600px;*/
        min-height: 320px;
        height:auto;
        /*background-color: transparent;*/
        text-align: left;
        padding:2em 2% 0;
        margin:0 auto;

        .dessous {
            z-index:1;
        }
        .dessus {
            z-index:2;
            position:relative;
        }

        .itemLogo {
            margin:0 auto;
            display:inline-block;
            vertical-align: middle;
            text-align: left;
        }
        #logoLorfolio {
            width:70%;
            padding:0 5%;
            a {
                max-width:412px;
                display:inline-block;
                width: 100%;
            }
        }
        #facilite {
            width:100%;
            max-width:900px;
            text-align: left;
            text-transform: uppercase;
            font-size:1.8vw ;
            line-height: 1.1em;
            padding:0.4em 3% 0 0;
            margin:0;
            color:#4B3C8F;
            font-weight: normal;

            span.retourLigne {
                /*
                display: inline !important;
                position: relative;
                padding: 0;
                */
                }


            @media screen and (max-width: 1200px) {
                font-size:1.4em ;
            }
            @media screen and (min-width: 1429px) {
                font-size:1.6em ;
            }

        }
        #logoGE {
            margin: 0 0 0 auto;
            width:20%;
            max-width:162px;
            text-align: right;
        }

        .nombril {
            position: absolute;
            top:0;
            left:50%;
            transform: translateX(-50%);
            max-height: 100vh;
            min-height: 560px !important;
        }
    }
</style>
