<template>
  <div id="header">
    <div class="gauche"></div>
    <div class="droite"></div>
    <header-logos-pro v-if="sitePRO" />
    <header-logos v-if="!sitePRO" />
    <bloc-publics v-if="!sitePRO" />
  </div>
</template>

<script>

import HeaderLogosPro from '@/components/pro/header/HeaderLogos.vue'
import HeaderLogos from '@/components/header/HeaderLogos.vue'
import Publics from '@/components/header/Publics.vue'

export default {
    name: 'Header',
    props: {
    },
    components: {
        'header-logos-pro': HeaderLogosPro,
        'header-logos': HeaderLogos,
        'bloc-publics': Publics,
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        sitePRO() {
            return(this.$parent.$parent.sitePRO);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #header {
        width:100%;
        height:auto;
        /*min-height:300px;*/
        background: no-repeat center bottom transparent url("~@/assets/img/header/fondh.jpg");
        .gauche, .droite {
            display:none;
        }
        .gauche {
            background: no-repeat right bottom transparent url("~@/assets/img/header/perso-gauche.png");
            position:absolute;
            left:0;
            bottom:0;
            height:100%;
            width:37%
        }
        .droite {
            background: no-repeat right bottom transparent url("~@/assets/img/header/perso-droite.png");
            position:absolute;
            right:0;
            bottom:0;
            height:100%;
            width:35%
        }
        /*background-size:auto;*/
        /*background-size:cover;*/
        #publics {
            background: no-repeat center bottom transparent url("~@/assets/img/header/bas.png");
        }
    }
    .addeoSmall {
        #header {
            background-position: center top;
            background-color: #E0E5CE;

            .gauche, .droite {
                display:none;
            }
        }
    }
    .addeoLarge, .addeoMedium {
        #header {
            .gauche, .droite {
                display:block;
            }

            @media screen and (max-width: 1750px) {
                .gauche, .droite {
                    display:block;
                }
                .gauche {
                    background-position: right bottom;
                }
                .droite {
                    background-position: left bottom;
                    width:27%
                }
            }
            @media screen and (max-width: 1550px) {
                .gauche {
                    width:38%
                }
                .droite {
                    width:23%
                }
            }
        }
    }
    .addeoMedium {
        #header {
            @media screen and (max-width: 1800px) {
                .gauche {
                    width:40%
                }
            }
            @media screen and (max-width: 1600px) {
                .gauche {
                    width:45%
                }
            }
            @media screen and (max-width: 1400px) {
                .gauche {
                    width:48%
                }
                .droite {
                    width:27%
                }
            }
            @media screen and (max-width: 1100px) {
                .gauche {
                    width:52%
                }
                .droite {
                    width:28%
                }
            }


        }
    }
</style>
