<style lang="css">
   @import './assets/styles/Font.css';
   @import './assets/font/font-awesome/css/font-awesome.css';
</style>

<template>
    <div id="app" v-bind:class="{addeoSmall : addeoSmall, addeoMedium : addeoMedium, addeoLarge : addeoLarge, addeoLargeLow : addeoLargeLow, pro : sitePRO}">

    <div id="blocVersion">
        version : {{version}}
    </div>

    <transition name="fadeSmooth">
        <div id="message" v-bind:class="{messageErreur : !messageOK}"   v-if="message">{{messageTxt}}</div>
    </transition>

    <router-view/>
  </div>
</template>



<script>
    

    import { addeoPost } from "./js/datas.js"

    export default {
        name: "NomTransitoire",
        data() {
            return {
                sitePRO:false,
                refreshing: false,
                tmp:true,
                addeoHaut:true,
                addeoSmall:false,
                addeoMedium:false,
                addeoLarge:false,
                addeoLargeLow:false,
                myTimeout:null,
                myTimeout2:null,
                rafraichir:false,
                rafraichirDemande:false,
                messageTxt:"",
                messageOK:true,
                message:false
            }
        },
        components: {
        },


        created() {
            this.initialiserResolution();
//            this.$vuetify.icons.checked = 'fas fa-check-square'
//            this.$vuetify.icons.unchecked = 'far fa-square'

            document.addEventListener('swUpdated', this.updateAvailable, { once: true });

            this.myTimeout2 = setTimeout(this.pourSW, 750);
        },

        mounted() {

            window.addEventListener("resize", this.myEventHandlerResize);

            this.name = this.lAppli;

        },

        watch:{
            rafraichir(newValue) {
                if (newValue && 0) {
                    this.rafraichir = false;
                    console.log('before reload');
                    setTimeout(function(){
                        console.log('reload en cours');
                        window.location.reload();
                    }, 3000);
                }
            },
        },

        destroyed() {
            window.removeEventListener("resize", this.myEventHandlerResize);
        },


        computed: {
            version() {
                return(this.$router.options.version);
            },
            markOrientest() {
                return(this.$router.options.markOrientest);
            },
            paramPRO() {
                return(this.$router.options.paramPRO);
            },
            lAppli() {
                if (this.markOrientest) {
                    if (!this.paramPRO) {
                        return("MonOrientEst");
                    }
                    else {
                        return("BaOOrientEst");
                    }
                }
                else {
                    if (!this.paramPRO) {
                        return("Lorfolio");
                    }
                    else {
                        return("LorfolioPro");
                    }
                }
            }
        },

        methods: {
            pourSW() {
                if (this.myTimeout2) {
                    clearTimeout(this.myTimeout2);

                }

                console.log('test reload');
                if ((typeof navigator !== "undefined") && (typeof navigator.serviceWorker !== "undefined")) {
                    // Prevent multiple refreshes
                    navigator.serviceWorker.addEventListener('controllerchange', () => {
                        if (this.refreshing) return
                        this.refreshing = true
                        // Here the actual reload of the page occurs
                        console.log('before reload');
                        window.location.reload()
                    })
                }
                else {
                    console.log('no reload');
                }
            },
            finMessage() {
                if (this.myTimeout) {
                    clearTimeout(this.myTimeout);
                }
                this.message = false;
            },
            myEventHandlerResize(e) {
                this.initialiserResolution();
            },
            initialiserResolution() {
                if (document.body) {
                    var hauteurEcran = window.innerHeight;
                    var largeurEcran = document.body.clientWidth;
                    if (largeurEcran >= 992) {
                        this.addeoSmall=false;
                        this.addeoMedium=false;
                        this.addeoLarge=true;
                        if (largeurEcran >= 1182) {
                            this.addeoXLarge=true;
                        }
                        else {
                            this.addeoXLarge=false;
                        }
                    }
                    else {
                        if (largeurEcran >= 641) {
                            this.addeoLargeLow=false;
                            this.addeoSmall=false;
                            this.addeoLarge=false;
                            this.addeoMedium=true;
                        }
                        else {
                            this.addeoLargeLow=false;
                            this.addeoLarge=false;
                            this.addeoMedium=false;
                            this.addeoSmall=true;
                        }
                    }
                    if (hauteurEcran < 775) {
                        this.addeoHaut=false;
                        if (this.addeoLarge) {
                            this.addeoLargeLow=true;
                            this.addeoLarge=false;
                            this.addeoMedium=true;
                        }
                    }
                }
            },
            updateAvailable(event) {
                console.log('swUpdatedDetected');
                this.registration = event.detail;
                this.refreshApp();
            },
            /*
            // Called when the user accepts the update
            refreshApp() {
                console.log('refreshApp');
                // Make sure we only send a 'skip waiting' message if the SW is waiting
                if (!this.registration || !this.registration.waiting) return

                var thisObj = this;

                navigator.serviceWorker.getRegistrations().then(function(registrations) {
                    for(let registration of registrations) {
                        registration.unregister(); // pris en compte uniquement après reload !!!!
                        console.log('serviceWorker unregister : ');
                    }
                    // send message to SW to skip the waiting and activate the new SW
                    thisObj.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
                })

                // send message to SW to skip the waiting and activate the new SW
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            },
            */
            // à partir de STERES
            refreshApp() {
                console.log('refreshApp');
                // Make sure we only send a 'skip waiting' message if the SW is waiting
                //if (!this.registration || !this.registration.waiting) return
                console.log('refreshApp OK');

                var thisObj = this;
                /**/
                navigator.serviceWorker.getRegistrations().then(function(registrations) {
                    for(let registration of registrations) {
                        registration.unregister(); // pris en compte uniquement après reload !!!!
                    }
                    console.log('serviceWorker unregister : ');
                    // send message to SW to skip the waiting and activate the new SW
                    if (thisObj.registration && thisObj.registration.waiting) {
                        thisObj.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
                    }

                    console.log('refreshApp OK 1');

                    // BC 2021/04 - reload
                    if (!thisObj.rafraichirDemande) {
                        thisObj.rafraichir = true;
                    }
                    thisObj.rafraichirDemande = true;
                    return;
                })
            },

        }
    }

    
</script>

<style lang="scss">



html {
    height: 100%;

    body {
        min-height: 100%;
        margin: 0;
        padding: 0;
        position:relative;
        text-align: center;
        min-width: 320px;

        #app {

            *, :after, :before {
                background-repeat: no-repeat;
                box-sizing: content-box;
            }

            &.addeoSmall {
                .containerAffichage {
                    width:96%;
                    /*margin:0 auto;*/
                    margin:4em auto;
                }
            }
            &.addeoMedium {
                .containerAffichage {
                    width:100%;
                    max-width: 980px;
                    margin:4em auto;
                }
            }


            #message {
                position: fixed;
                bottom:2em;
                width: 90%;
                padding:1em 5%;
                /*
                position: absolute;
                bottom:0vh;*/
                /*transform: translateY(-50vh);*/
                left:0;
                text-align: center;
                background-color: #4B3C8F;
                color:white;
                z-index: 500;
                height: 3em;
                line-height: 1.3em;
                box-shadow: 0 1em 1em #777;
            }

.fadeSmooth-enter-active {
    transition: all .1s ease;
}
.fadeSmooth-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fadeSmooth-enter, .fadeSmooth-leave-to {
    opacity: 0;
}

            position:relative;
            width:100%;
            max-width: 1920px;
            min-width: 320px;
            margin:0 auto;
            background-color: white;

            .mevMoyen {
                font-weight: 600;
            }
            .insecable {
                white-space: nowrap;
            }

            :focus {
                outline: transparent solid 1px  !important;
            }

            a {
                text-decoration: none;
                color: #33AD98;
                &:hover {
                    color : #4B3C8F;
                    }
            }
            div {
                position : relative;
            }

            .containerAffichage {
                width:98%;
                max-width: 1260px;
                margin:4% auto;
            }

            h2.sousTitre {
                color:#356A72;
                text-transform: uppercase;
                font-size:2.6em;
                vertical-align: middle;
                background: no-repeat center bottom transparent url("~@/assets/img/barre.svg");
                padding-bottom: 0.5em;
                margin-bottom: 1em;

                img {
                    max-width:212px;
                    &.marqueOrientest {/* MARK ORIENTEST */
                        max-height: 0.7em;
                        max-width:none;
                    }
                }
                .commeSousTitre {
                    color:#4b3c8f;
                }

            }


            font-family: Montserrat, Roboto, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-align: center;
            color: #2c3e50;

            .clear {
                clear: both;
            }
        }
    }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: pink;
    }
  }
}

    /************************************************ PRO *********************************************/

html {
    body {

#app {
    form {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px #EBEBEB inset !important;
            -webkit-text-fill-color: 2C3E50 !important;
        }
        ::-ms-reveal {
            display: none;
        }
    }
    input:-internal-autofill-selected {
        appearance: menulist-button;
        /*background-color: rgba(0, 0, 0, 0.2) !important;*/
        background-color: #EBEBEB;
        color: #2C3E50;
        /*background-image: none !important;*/
        /*color: -internal-light-dark(black, white) !important;*/
    }
    input {
        filter: none !important;
    }
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }

    #blocVersion {
        color:transparent;
        background: transparent;
        position: absolute;
    }
}

        #app.pro {
            h2.sousTitre {
                text-transform: unset;
                background: no-repeat center bottom transparent url("~@/assets/img/pro/barre.svg");
            }
            &.addeoSmall {
                h2.sousTitre {
                    max-width: 95%;
                    font-size: 2.4em;
                    }
            }
            > div {
                background-color: #F8F8F8;

                &.home {
                    background-color: white;
                }
            }
            .lien {
                cursor:pointer;
            }
            .teaser {
                text-align:justify ;
                margin : 2em auto;
                width:100%;
                max-width: 600px;
                font-size:1.2em;
            }
            &.addeoSmall {
                .teaser {
                    text-align:left;
                    font-size:1.1em;
                }
            }
        }
    }
}

</style>
