<template>
  <div id="inscription">
      <span class="titre">Pas encore de compte ?</span>
      <a v-if="markOrientest" :href="urlInscription" class="bouton">Je crée Mon OrientEst</a>
      <a v-if="!markOrientest" :href="urlInscription" class="bouton">Je crée mon compte Lorfolio</a>

  </div>
</template>

<script>
export default {
    name: 'Inscription',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        urlInscription() {
            var leDomaine = window.location.hostname;
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                return("http://grandtest.addeobx.bsq/utilisateurs/inscription"); // URL de test
            }

            if (leDomaine.indexOf("grandtest") >= 0) {
                return("https://grandtest.addeobx.bsq/utilisateurs/inscription"); // URL de test
            }

            if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                return("https://preprod.lorfolio.fr/utilisateurs/inscription"); // URL de preprod
            }
            else {
                 if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                    return('https://mon.lorfolio.fr/utilisateurs/inscription') // URL de prod
                 }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #inscription {
        width:90%;
        height:auto;
        background: no-repeat center bottom transparent url("~@/assets/img/inscription/fond.jpg");
        text-transform: uppercase;
        padding:4em 5%;

        .titre {
            display:block;
            color: #4B3C8F;
            margin-bottom: 2em;
            font-weight: bold;
            font-size:1.2em;
        }
        a.bouton {
            display:inline-block;
            /*
            border: 1px solid #4B3C8F;
            background-color: #4B3C8F;
            */
            border: 1px solid #356a73;
            background-color: #356a73;
            color: white;
            padding: 0.6em 0.8em;
            font-size: 0.8em;
            font-weight: 600;

            &:hover {
                /*opacity: 0.5;*/
                background-color: white;
                /*
                color: #4B3C8F;
                */
                color: #356a73;
            }
        }
    }
</style>
