<template>
    <div id="footerMenu" class="itemFooter">
        <div class="container" v-if="markOrientest">
            <div class="menuTitre">Accès rapide</div>
            <ul class="menuListe marquerLiensExternes" >
                <li><a target="_blank" title="Orient'Est" href="https://www.orientest.fr">Orient'Est</a></li>
                <li v-if="$parent.$parent.$parent.sitePRO"><a target="_blank" title="Mon Orient'Est" href="https://www.monlorfolio.fr">Mon Orient'Est</a></li>
                <li><a target="_blank" title="Agenda de l'orientation" href="https://agenda.orientest.fr">Agenda de l'orientation</a></li>
                <li><a target="_blank" title="Annuaire de l'orientation" href="https://annuaire.orientest.fr">Annuaire de l'orientation</a></li>
                <li><a target="_blank" title="Ressources" href="https://www.orientest.fr/ressources-metier">Ressources</a></li>
                <li><a target="_blank" title="Actualités" href="https://www.orientest.fr/actualites-orient-est">Actualités</a></li>
            </ul>


            <div class="menuTitre">À propos</div>
            <ul class="menuListe marquerLiensExternes" v-if="!$parent.$parent.$parent.sitePRO">
                <li v-if="0"><a target="_blank" title="Webfolios Grand Est" :href="urlWebfolios">Les Webfolios</a></li>
                <li><router-link to="/partenaires">Partenaires</router-link></li>
                <li><router-link to="/mentions-legales">Mentions légales</router-link></li>
                <li><router-link :to="{ name: 'Contact', params: { pere: 'footer' }}">Contact</router-link></li>
            </ul>

            <ul class="menuListe marquerLiensExternes" v-if="$parent.$parent.$parent.sitePRO">
                <li><router-link to="/partenaires-pro">Partenaires</router-link></li>
                <li><router-link to="/mentions-legales-pro">Mentions légales</router-link></li>
                <li><router-link :to="{ name: 'ContactPro', params: { pere: 'footerPro' }}">Contact</router-link></li>
            </ul>

        </div>

    </div>
</template>

<script>
export default {
    name: 'FooterMenu',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        laRoute() {
            return(this.$route.name);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        urlWebfolios() {
            var urlFolios = "https://webfolios.grandtest.addeo.ovh";
            if (this.$parent.$parent.$parent.isPreProdApp) {
                urlFolios =  "https://preprod.webfolios.orientest.fr";
            }
            else {
                if (this.$parent.$parent.$parent.isProdApp) {
                    urlFolios =  "https://webfolios.orientest.fr";
                }
            }
            return(urlFolios);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .addeoSmall {
        #footerMenu {
            .container {
                .menuListe {
                    padding-left: 0;
                }
            }
        }
    }
    .addeoSmall, .addeoMedium {
        #footer {
            #footerMenu {
                display: block;
                float:none;
                margin:4em auto;
                padding:0;
            }
        }
    }

    #footer {

        #footerMenu {
            float: right;
            text-transform: uppercase;
            background-color: transparent;
            text-align: center;
            padding-left:10%;
            width:40%;

            .container {
                text-align: left;
                margin:0 auto;
                width:100%;
                max-width: 250px;
                padding:0;

                .menuTitre {
                    display: block;
                    /*color:#33AD98;*/
                    margin-bottom:2em;
                    font-size:110%;
                    font-weight: bold;
                    &:after, &::after {
                        content: " ";
                        border-bottom: 3px solid #006EAB;
                        height: 5px;
                        width: 60px;
                        position: absolute;
                        left: 0;
                        bottom: -10px;
                    }
                }

                .menuListe {
                    font-size:90%;
                    list-style-type:none;
                    padding-left: 1em;
                    &:first-of-type {
                        margin-bottom:3em;
                    }
                    &:lasst-of-type {
                        margin-bottom:0;
                    }
                    li {
                        margin-top:0.6em;

                        a {
                            /*color:white;*/
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
</style>
