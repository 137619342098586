
import axios from 'axios'
const DELAI_CACHE = 2 * 60 * 1000; // le premier chiffre donne les minutes
/*
import { setupCache } from 'axios-cache-adapter'

// Create `axios-cache-adapter` instance
const cacheAddeo = setupCache({
  maxAge: 2 * 60 * 1000
})
// Create `axios` instance passing the newly created `cache.adapter`
const apiCacheAddeo = axios.create({
    adapter: cacheAddeo.adapter,
    //baseURL: ajaxDomaine()+"/agenda/ajax_api_portail_rencontres",
    //headers: {'Cache-Control': 'max-age=120'}
})
*/

export const addeoGet = {

        navigateurEnLigne() {
            return(navigateurEnLigne());
        },

        async getRencontres(leStore) {
            if (navigator.onLine) {
                var paramApi = ajaxDomaine()+"/agenda/ajax_api_portail_rencontres";
                return(getDataApi(paramApi, leStore));
            }
            else {
                return(null)
            }
        },

    }
export const addeoPost = {

        navigateurEnLigne() {
            return(navigateurEnLigne());
        },

        async posterContact(contact) {
            if (navigator.onLine) {
                var paramApi = ajaxDomaine2()+"/proxy.php?route=/remarques/add_from_portail";
                //var paramApi = proxyDomaine()+"/proxy.php?route=/remarques/add_from_portail";

                return(postDataApi(paramApi, contact));
            }
            else {
                return(null)
            }
        },
    }


async function getDataApi(paramApi, leStore) {
        var leMaintenant = Date.now();
        var localCacheAddeo = leStore.getters.cacheAddeo;
        if (localCacheAddeo && (typeof localCacheAddeo.expires !== "undefined") && (leMaintenant < localCacheAddeo.expires)) {
            return(localCacheAddeo);
        }

    if (navigator.onLine) {
        appAttendre(true); // on attend

        return axios.get(paramApi)
            .then(response => {
                appAttendre(false); // on n'attend plus

                if ((typeof response !== "undefined") && (typeof response.data !== "undefined")) {
                    // cache totalement manuel
                    localCacheAddeo = new Object();
                    localCacheAddeo.expires = leMaintenant+DELAI_CACHE;
                    localCacheAddeo.data = response.data;
                    leStore.commit('setCacheAddeo', localCacheAddeo); // on commence par stocker
                    return(response);
                }
                else {
                    return(null)
                    }
                })
            .catch(e => {
                return(null);
                });

        /*
// avec cache de axios-cache-adapter : j'ai pas compris l'intérêt !!!
        return apiCacheAddeo({
            url: paramApi,
            method: 'get'
            }).then(async (response) => {

            var retour = JSON.parse(cacheAddeo.store.store[paramApi]);
            leStore.commit('setCacheAddeo', retour); // on commence par stocker
            var retourData = retour.data;

            return(retourData);
            })

        */
        }
    else {
        appAttendre(false); // on n'attend plus
        return(null);
    }
}

async function postDataApi(paramApi, paramPost) {
    if (navigator.onLine) {
        appAttendre(true); // on attend

        // pour le croos domain
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        // et le post
        return axios.post(paramApi, {
            param:paramPost
            })
            .then(response => {
                appAttendre(false); // on n'attend plus

                if ((typeof response !== "undefined") && (typeof response.data !== "undefined")) {
                    return(response.data);
                }
                else {
                    return(null)
                    }
                })
            .catch(e => {
                appAttendre(false); // on n'attend plus
                return(null);
                });
        }
    else {
        appAttendre(false); // on n'attend plus
        return(null);
    }
}

async function postDataApi2(paramApi, paramPost) {
    var apikey = "aGxuvXkn3m6t";
    if (navigator.onLine) {
        appAttendre(true); // on attend

        // pour le cross domain
        //axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        /*
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['apikey'] = apikey;
        */
        const config = {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Access-Control-Allow-Origin': '*',
                'apikey': apikey
            }
        }

        /*const params = new URLSearchParams();
        params.append('data', paramPost);
        */
        var params = paramPost;

        // et le post
/*        return axios.post(paramApi, {
            data:paramPost
            })
*/
        return axios.post(paramApi, params, config)
            .then(response => {
                appAttendre(false); // on n'attend plus

                if ((typeof response !== "undefined") && (typeof response.data !== "undefined")) {
                    return(response.data);
                }
                else {
                    return(null)
                    }
                })
            .catch(e => {
                appAttendre(false); // on n'attend plus
                return(null);
                });
        }
    else {
        appAttendre(false); // on n'attend plus
        return(null);
    }
}


function toggleClassElt(elt, addRemoveClass, className) {
    if (elt) {
        if (addRemoveClass === 'addClass') {
            elt.classList.add(className);
        }
        else {
            elt.classList.remove(className);
        }
    }
}

function appAttendre(attendre) {
        var addRemoveClass ='addClass';
        if (!attendre) {
            addRemoveClass ='removeClass';
        }
        var eltApp = document.getElementById("app");
        toggleClassElt(eltApp, addRemoveClass, "curseurAttendre");
    }

function ajaxDomaine2() {

    var leDomaine = window.location.hostname;
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        return("http://portailfolio.grandtest.addeobx.bsq/");
    }

    if (leDomaine.indexOf("grandtest") >= 0) {
        return("http://"+leDomaine);
    }
    return("https://"+leDomaine);
}

function ajaxDomaine() {

    var leDomaine = window.location.hostname;
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        return("http://grandtest.addeobx.bsq");
    }

    if (leDomaine.indexOf("grandtest") >= 0) {
        return("https://grandtest.addeobx.bsq");
    }

    if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
        return("https://preprod.lorfolio.fr");
    }
    else {
         if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
            return('https://mon.lorfolio.fr') // la bonne URL
         }
    }
}

function proxyDomaine() {

    var leDomaine = window.location.hostname;
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        //return("http://"+window.location.host);
        return(window.location.host);
    }

    if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
        if ((leDomaine == "lorfoliopro.addeo.com") || (leDomaine == "www.lorfoliopro.addeo.com")) {
            return('https://lorfoliopro.addeo.com');
            }
        return('https://lorfoliovue.addeo.com');
    }
    else {
         if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr")) {
            return('https://www.lorfolio.fr') // la bonne URL
         }
        else {
            return('https://www.lorfolio.pro') // la bonne URL
        }
    }
}

function lURLDomaine() {
    var leDomaine = window.location.hostname;
    // quel domaine
    if ( leDomaine.indexOf("localhost") >= 0 ) {
        return("https://grandtest.addeobx.bsq"); // URL de test
    }
    else {
        if (leDomaine.indexOf("grandtest") >= 0) {
            return("https://grandtest.addeobx.bsq"); // URL de test
        }
        else {
            if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                return("https://preprod.lorfolio.fr"); // URL de preprod
            }
            else {
                 if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                    return('https://mon.lorfolio.fr'); // URL de prod
                 }
            }
        }
    }
}

function skipProxy() {
    var urlPOST = lURLDomaine();
    /*
    // pro ou pas pro ?
    if (this.$parent.sitePRO) {
        urlPOST += this.lesPros;
    }
    else {
        urlPOST += this.lesAutres;
    }
    */
    return(urlPOST);
    }

