<template>
    <div id="headerLogos">
        <div id="logoLorfolio" class="itemLogo">
            <a href="/" class="" title="Mon OrientEst" v-if="markOrientest">
                <img src="../../assets/img/header/logoMonOrientest.svg" alt="Mon OrientEst" class="centrer" />
            </a>
            <a href="/" class="" title="Lorfolio" v-if="!markOrientest">
                <img src="../../assets/img/header/logoLorfolio.svg" alt="Lorfolio" class="centrer" />
            </a>
        </div>
        <h1 id="prepare" class="itemLogo">
            <span>
                Toujours préparé<br/>pour mes choix professionnels
            </span>
        </h1>
        <div v-if="addeoLarge" id="logoGE" class="itemLogo">
            <a href="https://www.grandest.fr" target="_blank" rel="noopener" class="" title="Région Grand Est">
                <img src="../../assets/img/header/logoGrandE.svg" alt="Région Grand Est" class="centrer" />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderLogos',
    props: {
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed:{
        addeoSmall() {
            return(this.$parent.$parent.$parent.addeoSmall);
        },
        addeoMedium() {
            return(this.$parent.$parent.$parent.addeoMedium);
        },
        addeoLarge() {
            return(this.$parent.$parent.$parent.addeoLarge);
        },
        markOrientest() {
            return(this.$router.options.markOrientest);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .addeoMedium {
        #headerLogos {

            .itemLogo {
                &#logoLorfolio {
                    width:34%;
                    padding: 0 0 0 8%;
                }
                &#prepare {
                    width:54%;
                    padding: 0 1% 0 3%;
                    /*font-size: 1.1em;*/
                    font-size:2.5vw ;
                    line-height: 1em;
                    vertical-align: top;
                    @media screen and (min-width: 1200px) {
                        font-size: 1.7em;
                    }
                }
            }
        }
    }
    .addeoSmall {
        #headerLogos {

            .itemLogo {
                &#logoLorfolio {
                    width:90%;
                    margin:0 auto;
                    padding: 0;

                    a {
                        width:100%;
                        max-width: 400px;
                        display:inline-block;
                        text-align: center;
                    }
                }
                &#prepare {
                    width:90%;
                    margin:0 auto;
                    padding: 0;
                    font-size: 1.2em;
                    line-height: 1.1em;
                    text-align: center;

                    span {
                        width:100%;
                        max-width: 400px;
                        display:inline-block;
                        text-align: left;
                    }
                }
            }
        }
    }
    #headerLogos {
        width:96%;
        height:auto;
        background-color: transparent;
        text-align: center;
        padding:2em 2% 0;

        .itemLogo {
            margin:0 auto;
            display:inline-block;
            vertical-align: middle;
        }
        #logoLorfolio {
            max-width:412px;
            width:30%;
        }
        #prepare {
            width:50%;
            max-width:516px;
            text-align: left;
            text-transform: uppercase;
            font-size:2.2vw ;
            line-height: 1.1em;
            padding:0 3% 0 2%;
            margin:0;
            color:#4B3C8F;
            font-weight: normal;

            @media screen and (min-width: 1200px) {
                font-size:1.7em ;
            }

        }
        #logoGE {
            width:15%;
            max-width:162px;
            text-align: left;
        }
    }
</style>
