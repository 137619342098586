import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


console.log('Env : ',  process.env);
let paramPRO = process.env.VUE_APP_PRO;
let markOrientest = process.env.VUE_APP_MARK_ORIENTEST;
let version = process.env.VUE_APP_VERSION;
if (paramPRO == "false") {
    paramPRO = false;
}
else {
    if (paramPRO == "true") {
        paramPRO = true;
    }
}
if (markOrientest == "false") {
    markOrientest = false;
}
else {
    if (markOrientest == "true") {
        markOrientest = true;
    }
}
//const paramPRO = false;

console.log('paramPRO : ', paramPRO)
console.log('markOrientest : ', markOrientest)
console.log('version : ', version)

// paramétrage lorfolioPRO ou lorfolio.fr
// ***************************************************************************************************************************************************************************
if (paramPRO) {
    var siteId = 12;
}
else {
    var siteId = 6;
}

        function urlDomaine() {
            var leDomaine = window.location.hostname;
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                if (paramPRO) {
                    return("http://portailfoliopro.grandtest.addeobx.bsq");
                }
                else {
                    return("http://portailfolio.grandtest.addeobx.bsq");
                }
            }

            if ( leDomaine.indexOf("grandtest") > 0 ) {
                return("http://"+leDomaine);
            }
            else {
                return("https://"+leDomaine);
            }
        }


// le router ... enfin ...
// ***************************************************************************************************************************************************************************
Vue.use(VueRouter)

const routesFR = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/accueil',
    meta: {
      title: 'Lorfolio, toujours préparé pour mes choix professionnels en Grand Est',
      metaTags: [ {
          name: 'description',
          content: 'Quelle que soit ma situation, je bénéficie de tous les services utiles pour retracer mon parcours, identifier mes compétences, élaborer mon projet professionnel, créer des CV ou des sites web.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Lorfolio, toujours préparé pour mes choix professionnels en Grand Est'},
        {name: 'twitter:description', content: "Quelle que soit ma situation, je bénéficie de tous les services utiles pour retracer mon parcours, identifier mes compétences, élaborer mon projet professionnel, créer des CV ou des sites web."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfolio2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Lorfolio, toujours préparé pour mes choix professionnels en Grand Est'},
        {property: 'og:site_name', content: 'Lorfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfolio.png')},
        {property: 'og:description', content: "Quelle que soit ma situation, je bénéficie de tous les services utiles pour retracer mon parcours, identifier mes compétences, élaborer mon projet professionnel, créer des CV ou des sites web."}
        ]
    }
  },
  {
    path: '/partenaires',
    name: 'Partenaires',
    alias: '/partenaires',
    component: () => import( '../views/Partenaires.vue'),
    meta: {
      title: 'Les partenaires de Lorfolio',
      metaTags: [ {
          name: 'description',
          content: 'J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Lorfolio.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Les partenaires de Lorfolio'},
        {name: 'twitter:description', content: "J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Lorfolio."},
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfolio2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/partenaires'},
        {property: 'og:title', content: 'Les partenaires de Lorfolio'},
        {property: 'og:site_name', content: 'Lorfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfolio.png')},
        {property: 'og:description', content: "J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Lorfolio."}
        ]
    }
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    alias: '/mentionsLegales',
    component: () => import('../views/MentionsLegales.vue'),
    meta: {
      title: 'Lorfolio est un service édité par la région Grand Est',
      metaTags: [ {
          name: 'description',
          content: 'Vous disposez d’un compte personnel, gratuit et sécurisé.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Lorfolio est un service édité par la région Grand Est'},
        {name: 'twitter:description', content: "Vous disposez d’un compte personnel, gratuit et sécurisé."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfolio2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/mentions-legales'},
        {property: 'og:title', content: 'Lorfolio est un service édité par la région Grand Est'},
        {property: 'og:site_name', content: 'Lorfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfolio.png')},
        {property: 'og:description', content: "Vous disposez d’un compte personnel, gratuit et sécurisé."}
        ]
    }
  },
  {
    path: '/contact-lorfolio',
    name: 'Contact',
    pere:':pere',
    component: () => import( '../views/Contact.vue'),
    meta: {
      title: 'Contactez l’équipe Lorfolio',
      metaTags: [ {
          name: 'description',
          content: 'Faites nous connaître vos questions, vos suggestions, vos remarques.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe Lorfolio'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfolio2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-lorfolio'},
        {property: 'og:title', content: 'Contactez l’équipe Lorfolio'},
        {property: 'og:site_name', content: 'Lorfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfolio.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },
  {
    path: '*',
    name: 'Page inconnue',
    component: () => import('../views/404.vue')
  }
];

const routesPro = [
  {
    path: '/',
    name: 'Pro',
    alias: '/pro',
    component: () => import('../views/Pro.vue'),
    meta: {
      title: 'BàO OrientEst - métiers, formations : informer vos élèves !',
      metaTags: [ {
          name: 'description',
          content: "Boîte à Outils OrientEst - métiers, formations : informer vos élèves !"
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'BàO OrientEst - métiers, formations : informer vos élèves !'},
        {name: 'twitter:description', content: "Boîte à Outils OrientEst - métiers, formations : informer vos élèves !"},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'BàO OrientEst - métiers, formations : informer vos élèves !'},
        {property: 'og:site_name', content: 'BàO OrientEst'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Boîte à Outils OrientEst - métiers, formations : informer vos élèves !"}
        ]
    }
  },
  {
    path: '/partenaires-pro',
    name: 'Partenaires',
    alias: '/partenairesPro',
    // route level code-splitting
    // this generates a separate chunk (partenaires.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Partenaires.vue'),
    props: { sitePro: true},
    meta: {
      title: "Les partenaires d'OrientEst",
      metaTags: [ {
          name: 'description',
          content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Les partenaires de Lorfolio'},
        {name: 'twitter:description', content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/partenaires-pro'},
        {property: 'og:title', content: "Les partenaires d'OrientEst"},
        {property: 'og:site_name', content: 'BàO OrientEst'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."}
        ]
    }
  },
  {
    path: '/mentions-legales-pro',
    name: 'MentionsLegales',
    alias: '/mentionsLegalesPro',
    component: () => import('../views/MentionsLegales.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Lorfolio est un service édité par la Région Grand Est',
      metaTags: [ {
          name: 'description',
          content: "Vous disposez d’un compte PRO, gratuit et sécurisé."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Lorfolio est un service édité par la région Grand Est'},
        {name: 'twitter:description', content: "Vous disposez d’un compte PRO, gratuit et sécurisé."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/mentions-legales-pro'},
        {property: 'og:title', content: 'Lorfolio est un service édité par la région Grand Est'},
        {property: 'og:site_name', content: 'BàO OrientEst'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Vous disposez d’un compte PRO, gratuit et sécurisé."}
        ]
    }
  },
  {
    path: '/contact-lorfolio-pro',
    name: 'ContactPro',
    pere:':pere',
    alias: '/ContactPro',
    component: () => import( '../views/Contact.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Contactez l’équipe Lorfolio',
      metaTags: [ {
          name: 'description',
          content: "Faites nous connaître vos questions, vos suggestions, vos remarques."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe Lorfolio'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-lorfolio-pro'},
        {property: 'og:title', content: 'Contactez l’équipe Lorfolio'},
        {property: 'og:site_name', content: 'BàO OrientEst'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },
  {
    path: '/rencontres-pro',
    name: 'AgendaPro',
    alias: '/agenda-pro',
    component: () => import('../views/AgendaPro.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Explorez la face pro de Lorfolio',
      metaTags: [ {
          name: 'description',
          content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros de Lorfolio."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Explorez la face pro de Lorfolio'},
        {name: 'twitter:description', content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros de Lorfolio."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/rencontres-pro'},
        {property: 'og:title', content: 'Explorez la face pro de Lorfolio'},
        {property: 'og:site_name', content: 'BàO OrientEst'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros de Lorfolio."}
/**/
        ]
    }
  },

  {
    path: '/404',
    name: 'Page inconnue',
    component: () => import('../views/404Pro.vue')
  },
  {
    path: '*',
    name: 'Page inconnue',
    component: () => import('../views/404Pro.vue')
  },

];
if (paramPRO) {

console.log('paramPRO PRO : ', paramPRO)
    var routes = routesPro;
}
else {
console.log('paramPRO PAS PRO : ', paramPRO)
    var routes = routesFR;
}
const router = new VueRouter({
    paramPRO:paramPRO,
    markOrientest:markOrientest,
    version:version,
    siteId:siteId,
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return { x: 0, y: 0 };
  }
})


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router


