<template>
    <div id="connexion" v-bind:class="{header : inHeader}">

        <div class="containerConnexion" >
            <connexion-form />
        </div>
    </div>
</template>

<script>

import ConnexionForm from '@/components/ConnexionForm.vue'

export default {
    name: 'Connexion',
    props: {
        header: Boolean
    },
    data() {
        return {
            tmp:true,
            isHeader:this.header,
            ready:false,
            paramDomaine:"",
            paramCSS:"",
        }
    },

    components: {
        'connexion-form': ConnexionForm,
    },

    created() {
            this.initialiser();
            this.ready  = true;
    },

    computed: {
        inHeader() {
            return(this.isHeader);
        },
        urlDomaine() {
            var leDomaine = window.location.hostname;
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                return("http://grandtest.addeobx.bsq"); // URL de test
            }

            if (leDomaine.indexOf("grandtest") >= 0) {
                return("http://grandtest.addeobx.bsq"); // URL de test
            }

            if ( (leDomaine !== "lorfolio.fr") && (leDomaine !== "www.lorfolio.fr") && (leDomaine !== "lorfolio.pro") && (leDomaine !== "www.lorfolio.pro") && (leDomaine.indexOf("localhost")< 0) ) {
                return("https://preprod.lorfolio.fr"); // URL de preprod
            }
            else {
                 if ((leDomaine === "lorfolio.fr") || (leDomaine === "www.lorfolio.fr") || (leDomaine === "lorfolio.pro") || (leDomaine === "www.lorfolio.pro")) {
                    return('https://mon.lorfolio.fr') // URL de prod
                 }
            }
        },
        urlCSS() {
            var leDomaine = window.location.hostname;
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                if (this.sitePRO) {
                    return("http://portailfoliopro.grandtest.addeobx.bsq");
                }
                else {
                    return("http://portailfolio.grandtest.addeobx.bsq");
                }
            }

            if ( leDomaine.indexOf("grandtest") > 0 ) {
                return("http://"+leDomaine);
            }
            else {
                return("https://"+leDomaine);
            }
        },
        sitePRO() {
            if (typeof this.$parent.$parent.sitePRO != "undefined") {
                return(this.$parent.$parent.sitePRO);
            }
            return(this.$parent.$parent.$parent.sitePRO);
        },
        laProd() {
            return("mon.lorfolio.fr")
        },
        laPreprod() {
            return("preprod.lorfolio.fr")
        },
        lesPros() {
            return("/utilisateurs/iframe_login/pro")
        },
        lesAutres() {
            return("/utilisateurs/iframe_login")
        },
/*
        urlHeader() {
            var leDomaine1 = this.paramDomaine;
            if (this.sitePRO) {
                leDomaine1 += this.lesPros;
            }
            else {
                leDomaine1 += this.lesAutres;
            }
            var leCSS = this.paramCSS;
            leCSS = leCSS.replace(':', '%3A');
            leCSS = leCSS.replace('/', '%2F');
            return(leDomaine1+"?stylesheet="+leCSS+"%2Fstyles%2Fmon-lorfolio.css")
        },
        urlFooter() {
            var leDomaine2 = this.paramDomaine;
            if (this.sitePRO) {
                leDomaine2 += this.lesPros;
            }
            else {
                leDomaine2 += this.lesAutres;
            }
            var leCSS = this.paramCSS;
            leCSS = leCSS.replace(':', '%3A');
            leCSS = leCSS.replace('/', '%2F');
            return(leDomaine2+"?stylesheet="+leCSS+"%2Fstyles%2Fmon-lorfolio-footer.css")
        }
*/
    },
    methods: {
        initialiser() {
            this.paramDomaine = this.trouverBonDomaine();
            this.paramCSS = this.trouverBonCSS();

        },
        trouverBonDomaine() {
            return(this.urlDomaine);
            /*
            let leDomaine = window.location.hostname;
            let indice = leDomaine.indexOf("localhost");
            const test1 = (indice >= 0);
            const test2 = ( (window.location.hostname != "lorfolio.pro") && (window.location.hostname != "www.lorfolio.pro") && (window.location.hostname != "lorfolio.fr") && (window.location.hostname != "www.lorfolio.fr") );
            if (test1) {
                leDomaine = this.laProd; // en local, on appelle la PROD à cause de l'appel du pre-render
            }
            else  {
                if (test2) {
                    // leDomaine = this.laPreprod; pb cors
                    leDomaine = this.laProd;
                }
                else {
                    leDomaine = this.laProd;
                }
            }
            return(leDomaine);
            */
        },

        trouverBonCSS() {
            return(this.urlCSS);
            /* nouvelle version 2021/07 */
            /*
            var sitePRO = this.sitePRO;
            if (sitePRO) {
                return("www.lorfolio.pro");
            }
            return("www.lorfolio.fr");
            */
            /*
            let leDomaine = window.location.hostname;
            let indice = leDomaine.indexOf("localhost");
            const test0 = (indice >= 0);
            const test1 = ((window.location.hostname == "lorfolio.pro") || (window.location.hostname == "www.lorfolio.pro"));
            const test2 = ((window.location.hostname == "lorfolio.fr") || (window.location.hostname == "www.lorfolio.fr"));
            const test3 = ((window.location.hostname == "lorfoliopro.addeo.com"));
            if (test0) {
                if (this.$router.options.paramPRO) {
                    leDomaine = "www.lorfolio.pro";
                }
                else {
                    leDomaine = "www.lorfolio.fr";
                }
            }
            else {
                if (test1) {
                    leDomaine = "www.lorfolio.pro";
                }
                else  {
                    if (test2) {
                        leDomaine = "www.lorfolio.fr";
                    }
                    else {
                        if (test3) {
                            leDomaine = "lorfoliopro.addeo.com";
                        }
                        else { // par défaut
                            leDomaine = "www.lorfolio.fr";
                        }
                    }
                }
            }
            return(leDomaine);
            */
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
/*
    @media screen and (max-width: 579px) {
        #app {
            #connexion {
                height:200px;
            }
            #portail2appliIframe {
                min-height: 200px;
            }
        }
    }
*/
    .pro {
        #connexion {

            &.header {
                /*border-bottom: 8px solid #57bba9; ? */
                border-bottom: 8px solid #35AC98;
            }
        }
    }

    #connexion {
        width:100%;
        /*
        height:70px;
        height:82px;
        */
        /*height:100px;*/
        background-color: transparent;
        /*max-width: 560px; 2021 */
        margin:0 auto;

        .containerConnexion {
            width: 100%;
            max-width: 1260px;
            margin: 0 auto;
            text-align: right;
        }


        &.header {
            height:72px;
            max-width: 100%;
            border-bottom: 8px solid #FFD430;
            box-shadow: 0px 1px 20px #806464;
            z-index:1;

            a.logoGEHeader {
                position: absolute;
                left:10px;
                top:10px;
                z-index:50;
                width: 100%;
                max-width: 120px;

                img {
                    width:100%;
                    max-width: 120px;
                }
            }

            @media screen and (max-width: 700px) {
                a.logoGEHeader {
                    display:none;
                }
            }
        }

    }

    #portail2appliIframe {
        width: 100%;
        max-width: 993px;
        /*max-width: 900px;*/
        background-color: transparent;
        height:100%;
        position: relative;
        max-height: 70px;

        /*
        #identification.iframe {
            .login_form {
                box-shadow:none;

                div.input {
                    display:inline-block;
                }
            }
        }
        */
    }
</style>
